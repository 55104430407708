import React, { useCallback } from 'react';
import Link from 'next/link';
import { useFormContext } from 'react-hook-form';

import { Icon, IconPropsType } from 'components/tokens/Icon';
import { loader } from 'icons/misc';
import { getDataLayerEvents } from 'utils/getDatalayerEvents';

import styles from './Button.module.scss';

export enum ButtonType {
  Button = 'button',
  Reset = 'reset',
  Submit = 'submit',
}

export enum ButtonFontWeight {
  Regular = 'regular',
  SemiBold = 'semibold',
}

export enum ButtonVariant {
  Primary = 'primary',
  PrimarySkyBlue = 'primary-sky-blue',
  PrimaryMintSweet = 'primary-mint-sweet',
  PrimaryYellowish = 'primary-yellowish',
  Ghost = 'ghost',
  GhostInverted = 'ghost-inverted',
  Tertiary = 'tertiary',
  Text = 'text',
  PrimaryRedish = 'primary-redish',
  LinkText = 'link',
}

export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

interface ButtonProps {
  type: ButtonType;
  variant?: ButtonVariant;
  disabled?: boolean;
  href?: string | undefined;
  target?: string | undefined;
  nofollow?: boolean;
  size?: ButtonSize;
  stretch?: boolean;
  leadingIcon?: IconPropsType['Icon'];
  trailingIcon?: IconPropsType['Icon'];
  onClick?: React.MouseEventHandler;
  id?: string;
  isJustifyCenter?: boolean;
  fontWeight?: ButtonFontWeight;
  dataLayerEvent?: any;
}

const iconSize = {
  [ButtonSize.Small]: 14,
  [ButtonSize.Medium]: 18,
  [ButtonSize.Large]: 20,
};

export const Button: React.FC<ButtonProps> = ({
  children,
  type, // No default. This needs to be set each time for clarity.
  disabled = false,
  href = '',
  target = '',
  nofollow = false,
  variant = ButtonVariant.Primary,
  size = ButtonSize.Medium,
  stretch = true,
  leadingIcon,
  trailingIcon,
  onClick,
  id,
  isJustifyCenter,
  fontWeight = ButtonFontWeight.SemiBold,
  dataLayerEvent,
}) => {
  const context = useFormContext();
  const shouldDisable = context && context.formState.isSubmitting && type === ButtonType.Submit;
  const trailing = shouldDisable ? loader : trailingIcon;

  const handleLinkClick = useCallback(
    (e) => {
      if (dataLayerEvent) {
        getDataLayerEvents(dataLayerEvent);
      }
      if ((href as string).startsWith('#')) {
        e.preventDefault();
        const destination = document.getElementById(href.substring(1));
        if (destination) {
          destination.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }
    },
    [href, dataLayerEvent]
  );

  return href ? (
    <Link href={href}>
      <a
        tabIndex={0}
        role="link"
        onClick={handleLinkClick}
        onKeyDown={handleLinkClick}
        target={href.startsWith('http') ? '_blank' : target}
        rel={nofollow ? 'nofollow' : ''}
        className={`${styles.a} ${styles.button} ${styles[variant]} ${styles[size]} ${
          stretch ? styles.stretch : ''
        } ${leadingIcon || trailing ? styles.hasIcon : ''} ${
          fontWeight === ButtonFontWeight.Regular ? styles['font-regular'] : ''
        } ${isJustifyCenter ? styles['justify-center'] : ''}`}
      >
        {leadingIcon && (
          <span className={styles['leading-icon']}>
            <Icon size={iconSize[size]} icon={leadingIcon} />
          </span>
        )}
        {children}
        {trailing && (
          <span className={styles['trailing-icon']}>
            <Icon size={iconSize[size]} icon={trailing} />
          </span>
        )}
      </a>
    </Link>
  ) : (
    <button
      // https://github.com/yannickcr/eslint-plugin-react/issues/1555
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={shouldDisable ? true : disabled}
      className={`${styles.button} ${styles[variant]} ${styles[size]} ${
        isJustifyCenter ? styles['justify-center'] : ''
      } ${stretch && styles.stretch} ${leadingIcon || trailing ? styles.hasIcon : ''} ${
        fontWeight === ButtonFontWeight.Regular ? styles['font-regular'] : ''
      }`}
      onClick={(e) => {
        if (dataLayerEvent) {
          getDataLayerEvents(dataLayerEvent);
        }
        if (onClick) {
          onClick(e);
        }
      }}
      id={id}
    >
      {leadingIcon && (
        <span className={styles['leading-icon']}>
          <Icon size={iconSize[size]} icon={leadingIcon} />
        </span>
      )}
      {children}
      {trailing && (
        <span className={styles['trailing-icon']}>
          <Icon size={iconSize[size]} icon={trailing} />
        </span>
      )}
    </button>
  );
};
