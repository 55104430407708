import React from 'react';

import { Colors } from 'enums/Colors';

import styles from './Icon.module.scss';

export enum IconType {
  Fill = 'fill',
  Stroke = 'stroke',
}

export type IconPropsType = {
  Icon: SVG;
};

export type SVG = React.FC<React.SVGAttributes<SVGElement>>;

interface IconProps {
  icon: IconPropsType['Icon'];
  color?: Colors;
  size?: number;
  type?: IconType;
}

export const Icon: React.FC<IconProps> = ({
  icon,
  color = Colors.DarkBlue,
  size = 24,
  type = IconType.Stroke,
}) => {
  const IconLocal = icon;
  const style = {
    '--icon-color': `var(--color-${color})`,
    '--icon-size': `${size}px`,
  } as React.CSSProperties;

  return (
    <span className={`${styles.size} ${styles[type]}`} style={style}>
      <IconLocal width={size} height={size} />
    </span>
  );
};
