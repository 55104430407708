import React from 'react';

import { Button, ButtonType, ButtonVariant } from 'components/Button';
import { SVG } from 'components/tokens/Icon';

import styles from './MainNavigationDropdown.module.scss';

interface IListItem {
  item: JSX.Element;
  variant: ButtonVariant;
  leadingIcon: SVG;
  trailingIcon: SVG;
  href: string;
}

interface Props {
  title: string;
  list: IListItem[];
}

export const MainNavigationDropdown: React.FC<Props> = ({ title, list }) => (
  <section className={styles.dropdown} role="dialog">
    <div className={styles.arrow} />

    <div className={styles.content}>
      {list.map((item) => (
        <Button
          key={title}
          variant={item.variant}
          leadingIcon={item.leadingIcon}
          trailingIcon={item.trailingIcon}
          href={item.href}
          type={ButtonType.Button}
        >
          {item.item}
        </Button>
      ))}
    </div>
  </section>
);
