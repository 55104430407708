import React, { useContext, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import Logotype from 'public/assets/logotype/logotype.svg';
import { AuthContext, AuthContextType } from 'lib/auth/AuthContext';

import styles from './Header.module.scss';
import { MainNavigation } from './MainNavigation';
import { UserMenu } from './UserMenu';
import { SideNavigation } from './SideNavigation';

interface IProps {
  isInverted?: boolean;
}

export const Header: React.FC<IProps> = ({ isInverted = false }) => {
  const [isSideNavigationOpen, setIsSideNavigationOpen] = useState(false);
  const { isLoggedIn, handleLogout } = useContext(AuthContext) as AuthContextType;

  const router = useRouter();

  const handleOpenSideNavigation = () => {
    setIsSideNavigationOpen(true);
    document.body.classList.add('menu-open');
  };

  const handleCloseSideNavigation = () => {
    setIsSideNavigationOpen(false);
    document.body.classList.remove('menu-open');
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', handleCloseSideNavigation);

    return () => {
      router.events.off('routeChangeComplete', handleCloseSideNavigation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header className={`${styles.header} ${isInverted ? styles.inverted : ''}`}>
      <Link href="/" passHref>
        <a>
          <Logotype className={`${styles.logotype}`} />
        </a>
      </Link>

      <MainNavigation isInverted={isInverted} />

      <UserMenu
        isLoggedIn={isLoggedIn}
        isInverted={isInverted}
        onClickMenu={() => handleOpenSideNavigation()}
      />

      <SideNavigation
        handleLogout={handleLogout}
        isLoggedIn={isLoggedIn}
        open={isSideNavigationOpen}
        onClickClose={() => handleCloseSideNavigation()}
      />
    </header>
  );
};
