import React from 'react';

import { Colors } from 'enums/Colors';

import styles from './Divider.module.scss';

interface IProps {
  thickness?: number;
  borderColor?: Colors;
}

export const Divider: React.FC<IProps> = ({ thickness = 2, borderColor = Colors.StoneGrey900 }) => (
  <hr
    className={styles.hr}
    style={{ borderTopWidth: thickness, borderTopColor: `var(--color-${borderColor})` }}
  />
);
