import React, { useRef, useState } from 'react';
import Link from 'next/link';

import { Icon } from 'components/tokens/Icon';
import useOnClickOutside from 'utils/useOnClickOutside';
import { chevronDown } from 'icons/default';
import { Colors } from 'enums/Colors';
import { dropdownMenuBuy, dropdownMenuCompany } from 'content/menu';

import { MainNavigationDropdown } from '../MainNavigationDropdown';

import styles from './MainNavigation.module.scss';

interface IProps {
  isInverted?: boolean;
}

export const MainNavigation: React.FC<IProps> = ({ isInverted }) => {
  const buydropdownRef = useRef(null);
  const companydropdownRef = useRef(null);
  const [isBuyDropdownVisible, setIsBuyDropdownVisible] = useState(false);
  const [isCompanyDropdownVisible, setIsCompanyDropdownVisible] = useState(false);

  useOnClickOutside(buydropdownRef, () => setIsBuyDropdownVisible(false));
  useOnClickOutside(companydropdownRef, () => setIsCompanyDropdownVisible(false));

  return (
    <nav className={`${styles.navigation} ${isInverted ? styles.inverted : ''}`}>
      <span className={styles['top-element']} ref={buydropdownRef}>
        <button type="button" onClick={() => setIsBuyDropdownVisible(!isBuyDropdownVisible)}>
          Köpa
          <Icon icon={chevronDown} color={isInverted ? Colors.White : Colors.DarkBlue} size={13} />
        </button>

        {isBuyDropdownVisible && (
          <div className={`${styles['dropdown-layout']} ${styles.buy}`}>
            <MainNavigationDropdown list={dropdownMenuBuy.list} title={dropdownMenuBuy.title} />
          </div>
        )}
      </span>

      <Link href="/lanelofte-billan" passHref>
        <a className={styles['top-element']}>Billån</a>
      </Link>

      <Link href="/reloan" passHref>
        <a className={styles['top-element']}>Flytta ditt billån</a>
      </Link>

      <Link href="/salja-begagnad-bil" passHref>
        <a className={styles['top-element']}>Annonsera</a>
      </Link>

      <span className={styles['top-element']} ref={companydropdownRef}>
        <button
          type="button"
          onClick={() => setIsCompanyDropdownVisible(!isCompanyDropdownVisible)}
        >
          Företag
          <Icon icon={chevronDown} color={isInverted ? Colors.White : Colors.DarkBlue} size={13} />
        </button>

        {isCompanyDropdownVisible && (
          <div className={`${styles['dropdown-layout']} ${styles.company}`}>
            <MainNavigationDropdown
              list={dropdownMenuCompany.list}
              title={dropdownMenuCompany.title}
            />
          </div>
        )}
      </span>
    </nav>
  );
};
