import React, { createContext } from 'react';

import { Divider } from 'components/Divider';

const isLastChild = (childCount: number, currentIndex: number): boolean =>
  childCount === currentIndex + 1;

interface Props {
  divider?: boolean;
  isInverted?: boolean;
}

export const isInvertedContext = createContext(false);

export const Accordion: React.FC<Props> = ({ children, divider = true, isInverted = false }) => (
  <section className="accordion">
    {React.Children.toArray(children).map(
      (accordionItem, index) =>
        React.isValidElement(accordionItem) && (
          <isInvertedContext.Provider value={isInverted} key={accordionItem.key}>
            {accordionItem}
            {isLastChild(React.Children.count(children), index) ||
            isInverted ||
            !divider ? undefined : (
              <Divider />
            )}
          </isInvertedContext.Provider>
        )
    )}
  </section>
);
