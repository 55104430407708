import React, { MouseEventHandler, useContext, useEffect } from 'react';

import { Icon, IconPropsType, IconType } from 'components/tokens/Icon';
import { arrowRight, chevronDown, chevronUp } from 'icons/default';
import { Colors } from 'enums/Colors';

import { isInvertedContext } from '../Accordion';

import styles from './AccordionItem.module.scss';

export interface IProps {
  title: string;
  icon?: IconPropsType['Icon'];
  defaultOpen?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
}

export const AccordionItem: React.FC<IProps> = ({
  defaultOpen,
  children,
  title,
  icon,
  onClick = undefined,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const isInverted = useContext(isInvertedContext);

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    if (defaultOpen) {
      setIsOpen(true);
    }
  }, []);

  return (
    <div
      className={`${styles['accordion-item']} ${isInverted ? styles.inverted : ''}`}
      data-open={isOpen}
    >
      <div
        role="button"
        tabIndex={0}
        className={styles.heading}
        onKeyUp={handleKeyUp}
        onClick={(e) => (typeof onClick === 'function' ? onClick(e) : setIsOpen(!isOpen))}
      >
        {icon && (
          <Icon
            icon={icon}
            type={IconType.Stroke}
            color={isInverted ? Colors.White : Colors.DarkBlue}
          />
        )}

        <div className={styles.title}>{title}</div>

        {typeof onClick === 'function' ? (
          <div className={`${styles.icon} ${styles['icon-closed']}`}>
            <Icon
              icon={arrowRight}
              size={16}
              type={IconType.Stroke}
              color={isInverted ? Colors.White : Colors.DarkBlue}
            />
          </div>
        ) : (
          <>
            <div className={`${styles.icon} ${styles['icon-opened']}`}>
              <Icon
                icon={chevronUp}
                size={16}
                type={IconType.Stroke}
                color={isInverted ? Colors.White : Colors.DarkBlue}
              />
            </div>
            <div className={`${styles.icon} ${styles['icon-closed']}`}>
              <Icon
                icon={chevronDown}
                size={16}
                type={IconType.Stroke}
                color={isInverted ? Colors.White : Colors.DarkBlue}
              />
            </div>
          </>
        )}
      </div>

      <div className={styles.content}>{children}</div>
    </div>
  );
};
