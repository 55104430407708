export enum Colors {
  SkyBlue900 = 'sky-blue-900',
  SkyBlue700 = 'sky-blue-700',
  SkyBlue500 = 'sky-blue-500',
  SkyBlue300 = 'sky-blue-300',
  SkyBlue100 = 'sky-blue-100',
  SkyBlue = 'sky-blue',
  MintSweet900 = 'mint-sweet-900',
  MintSweet700 = 'mint-sweet-700',
  MintSweet500 = 'mint-sweet-500',
  MintSweet300 = 'mint-sweet-300',
  MintSweet100 = 'mint-sweet-100',
  MintSweet = 'mint-sweet',
  Redish900 = 'redish-900',
  Redish700 = 'redish-700',
  Redish500 = 'redish-500',
  Redish300 = 'redish-300',
  Redish100 = 'redish-100',
  Redish = 'redish',
  Yellowish900 = 'yellowish-900',
  Yellowish700 = 'yellowish-700',
  Yellowish500 = 'yellowish-500',
  Yellowish300 = 'yellowish-300',
  Yellowish100 = 'yellowish-100',
  Yellowish = 'yellowish',
  DarkBlue900 = 'dark-blue-900',
  DarkBlue700 = 'dark-blue-700',
  DarkBlue500 = 'dark-blue-500',
  DarkBlue300 = 'dark-blue-300',
  DarkBlue100 = 'dark-blue-100',
  DarkBlue = 'dark-blue',
  StoneGrey900 = 'stone-grey-900',
  StoneGrey700 = 'stone-grey-700',
  StoneGrey500 = 'stone-grey-500',
  StoneGrey300 = 'stone-grey-300',
  StoneGrey100 = 'stone-grey-100',
  StoneGrey = 'stone-grey',
  White = 'white',
  Error = 'error',
  Warning = 'warning',
  Valid = 'valid',
  Transparent = 'transparent',
  FlowSymbol = 'flow-symbol',
}
